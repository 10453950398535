.container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  .title {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }


  

  .btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 40px;
  }

  .btn-flw {
    margin: 5px;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  
  .generated-image {
    max-width: 100%;
    height: auto;
  }

  .middle{
    justify-content: center;
  }

  .loader {
    margin: 30px 0;
  }

  .image-container {
    margin: 20px;
    width: 800px;
    height: 800px;
    overflow: hidden;
    box-shadow: 0px 3px 10px rgba(0,0,0,0.1);
  }
  